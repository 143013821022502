import React, { useEffect, useState } from 'react';
import { FormGroup } from "reactstrap";
import Documentos from "../../components/Documentos";
import { host, useConstructor } from '../../Utils';
import axios from 'axios';

export const CordinacionArchivo = (props) => {
    useConstructor(() => props.setTitle("Sistema institucional de archivos"));
    const [anoSelected, setAnoSelected] = useState("");
    const [documents, setDocuments] = useState([]);
    const [anios, setAnios] = useState([]);
        
    useEffect(() => {
        const yearsMap = new Map();
        axios.get(host() + "/Publico/VersionDocumentos/FA-0280")
        .then((res) => {
            res.data.forEach(element => {
                const year = element.Seccion.match(/\d{4}/)?.[0]; // Extrae el año (4 dígitos)
                if (year) {
                    yearsMap.set(year, true); // Guardamos solo los años únicos
                }
            });
            const uniqueYears = Array.from(yearsMap.keys()).sort((a, b) => a - b);
            setAnios(uniqueYears); // Actualizamos el estado con los años únicos
            setDocuments(res.data);
            console.log("anios", uniqueYears)
        })
        .catch((res) => console.log(res));
    }, []);


    const valueSelected = (e) => {
        e.preventDefault()
        setAnoSelected(e.target.value)
    }
  return (
     <div>
        <p className="text-center title-extracto">
        Conjunto de registros, procesos, procedimientos, criterios, estructuras, herramientas y funciones que sustentan la actividad archivística del Instituto.
        </p>
            <div className="flex-container">
                <FormGroup>
                    <label>Seleccione Año de Filtro</label>
                    <select className="form-control" onChange={valueSelected}>
                        <option value="">Todos</option>
                        {anios.map(x=>(
                            <option key={x} value={x}>
                            {x}
                        </option>
                        ))}
                    </select >
                </FormGroup>
                <Documentos number="FA-0280" anoSelected={anoSelected} ></Documentos>
        </div>
      </div>
  )
}
