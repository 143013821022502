import React, { useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./pages/Home";
import { FetchData } from "./pages/FetchData";
import { InformesIndividuales } from "./pages/auditorias/InformesIndividuales";
import { AuditoriaIntegral } from "./pages/auditorias/AuditoriaIntegral";
import { InformeIndividual } from "./pages/InformeIndividual";
import { InformeCiudadano } from "./pages/auditorias/InformeCiudadano";
import { InformeCiudadanoActual } from "./pages/auditorias/InformeCiudadanoActual";
import { AuditoriasCovid } from "./pages/auditorias/AuditoriasCovid";
import { AvanceDeSolventacion } from "./pages/auditorias/AvanceDeSolventacion";
import CuentaPublica from "./pages/auditorias/CuentaPublica";
import CuentasPublicasAnteriores from "./pages/auditorias/CuentasPublicasAnteriores";
import { OtrosInformes } from "./pages/auditorias/OtrosInformes";
import InformesDeResultados from "./pages/auditorias/InformesDeResultados";
import { AcercaDe } from "./pages/institucion/AcercaDe";
import { Notas } from "./pages/difusion/Notas";
import { EntradasBlog } from "./pages/institucion/EntradasDeBlog";
import { ForosYEventos } from "./pages/difusion/ForosYEventos";
import { CodigoDeEtica } from "./pages/institucion/CodigoDeEtica";
import { Denuncias } from "./pages/institucion/Denuncias";
import { CompendioLegislativo } from "./pages/institucion/CompendioLegislativo";
import { Directorio } from "./pages/institucion/Directorio";
import { EstructuraOrganica } from "./pages/institucion/EstructuraOrganica";
// import { ParticipacionCiudadana } from "./pages/institucion/ParticipacionCiudadana";
import { LeyesDeIngresos } from "./pages/institucion/LeyesDeIngresos";
import { ManualesYGuias } from "./pages/institucion/ManualesYGuias";
import { OrganoDeControlInterno } from "./pages/institucion/OrganoDeControlInterno";
import { PreguntasFrecuentes } from "./pages/institucion/PreguntasFrecuentes";
import { ConveniosMunicipales } from "./pages/transparencia/ConveniosMunicipales";
import { InformeMensualDeTransparencia } from "./pages/transparencia/InformeMensualDeTransparencia";
import { LGCCyLeyDeAuseteridad } from "./pages/transparencia/LGCCyLeyDeAusteridad";
import { OblicacionesDeTransparencia } from "./pages/transparencia/ObligacionesDeTransparencia";
import { ProcedimientoDeAcceso } from "./pages/transparencia/ProcedimientoDeAcceso";
import { SolicitudDeInformacion } from "./pages/transparencia/SolicitudDeInformacion";
import { Sevac } from "./pages/transparencia/Sevac";
import { UnidadDeTransparencia } from "./pages/transparencia/UnidadDeTransparencia";
import Fraccion from "./pages/transparencia/ObligacionesTransparencia/Fraccion";
import Fracciones from "./pages/transparencia/ObligacionesTransparencia/Fracciones";
import { ProgramaAnual } from "./pages/inicio/ProgramaAnual";
import { InformeActividades } from "./pages/inicio/InformeActividades";
import { InformeSemestral } from "./pages/inicio/InformeSemestral";
import { ServiciosPublicos } from "./pages/inicio/ServiciosPublicos";
import { Corrupcion } from "./pages/inicio/Corrupcion";
import { Capacitaciones } from "./pages/institucion/Capacitaciones";
import { Nota } from "./pages/difusion/Nota";
import { Carnet } from "./pages/Carnet";
import { NotFound } from "./pages/NotFound";
import { AvisoPrivacidad } from "./pages/transparencia/AvisoPrivacidad";
import { DenunciasOci } from "./pages/institucion/DenunciasOci";
import { InformesAnterioresEstatales } from "./pages/auditorias/InformesAnterioresEstatales";
import { InformesAnterioresMunicipales } from "./pages/auditorias/InformesAnterioresMunicipales";
import { InformeIndicadores } from "./pages/auditorias/InformeIndicadores";
import { NormatividadInterna } from "./pages/institucion/NormatividadInterna";
import { NormatividadTransparencia } from "./pages/transparencia/NormatividadTransparencia";
import { Entrada } from "./pages/institucion/Entrada";
import { PlaneacionInstitucional } from "./pages/institucion/planeacion-institucional";
import { MapasInteractivos } from "./pages/institucion/MapasInteractivos";
import PresupuestosIngresos from "./pages/auditorias/PresupuestosIngresos";
import PresupuestosEgresos from "./pages/auditorias/PresupuestosEgresos";
import ProgramaAuditoriasDocumento from "./pages/auditorias/ProgramaAuditoriasDocumento";
import ProgramaAuditoriasGraficas from "./pages/auditorias/ProgramaAuditoriasGraficas";
import { InformesCuentaActual } from "./pages/auditorias/InformesCuentaActual";
import { InformesCuentaActualDetalle } from "./pages/auditorias/InformesCuentaActualDetalle";
import { RevistaValorPublico } from "./pages/difusion/RevistaValorPublico";
import { InformesTematicos } from "./pages/evaluaciones/InformesTematicos";
import { PoliticasPublicas } from "./pages/evaluaciones/PoliticasPublicas";
import AuditoriasCP2324 from "./pages/auditorias/AuditoriasCP2324";
import { ListaAcuerdos } from "./pages/institucion/ListaAcuerdos";    
import { ParticipacionCiudadana } from "./pages/auditorias/ParticipacionCiudadana";
import { MarcoNormativo } from "./pages/institucion/MarcoNormativo";
import { DocumentosDeInteres } from "./pages/institucion/DocumentosDeInteres";
import { ListaAvisos } from "./pages/institucion/ListaAvisos";    
import { CordinacionArchivo } from "./pages/institucion/CordinacionArchivo";
//import { Mapa } from "./pages/institucion/Mapa";



export default function App() {
  const [title, setTitle] = useState("");
  const updateTitle = (title) => {
    setTitle(title);
  };

  let history = useHistory();
  let route = history.location.pathname;

  if (route === "/transparencia") history.push("/transparencia/obligaciones-de-transparencia")


  return (
    <Layout title={title}>
      <Switch>
        <Route exact path="/">
          <Home setTitle={updateTitle} />
        </Route>
        <Route exact path="/fetch-data">
          <FetchData setTitle={updateTitle} />
        </Route>
        <Route exact path="/informes-individuales-estatal">
          <InformeIndividual setTitle={updateTitle} />
        </Route>
        <Route exact path="/auditorias/informes-ciudadanos">
                  <InformeCiudadano setTitle={updateTitle} />
        </Route>      
        <Route exact path="/auditorias/informes-ciudadanos-actual">
                  <InformeCiudadanoActual setTitle={updateTitle} />
        </Route>      
        
        <Route exact path="/auditorias/informes-individuales">
          <InformesIndividuales setTitle={updateTitle} />
        </Route>

        <Route exact path="/auditorias/informes-anteriores-estatales">
          <InformesAnterioresEstatales setTitle={updateTitle} />
        </Route>

        <Route exact path="/auditorias/auditoria-integral">
          <AuditoriaIntegral setTitle={updateTitle} />
        </Route>

        <Route exact path="/auditorias/presupuestosingresos">
          <PresupuestosIngresos setTitle={PresupuestosIngresos} />
        </Route>
        
        <Route exact path="/auditorias/ParticipacionCiudadana">
            <ParticipacionCiudadana />
        </Route>
        

        <Route exact path="/auditorias/presupuestosegresos">
            <PresupuestosEgresos setTitle={PresupuestosEgresos} />
        </Route>

        <Route exact path="/auditorias/programaauditoriadocumento">
            <ProgramaAuditoriasDocumento setTitle={ProgramaAuditoriasDocumento} />
        </Route>

        <Route exact path="/auditorias/programaauditoriagraficas">
            <ProgramaAuditoriasGraficas setTitle={ProgramaAuditoriasGraficas} />
        </Route>

        <Route exact path="/auditorias/informes-anteriores-municipales">
          <InformesAnterioresMunicipales setTitle={updateTitle} />
        </Route>
        <Route exact path="/informes/informe-indicadores">
          <InformeIndicadores setTitle={updateTitle} />
        </Route>
        <Route exact path="/auditorias/auditorias-covid">
          <AuditoriasCovid setTitle={updateTitle} />
        </Route>

        <Route exact path="/informes/cuenta-publica">
          <CuentaPublica tipoInforme={"Cuenta"} setTitle={updateTitle} />
        </Route>

        <Route exact path="/informes/cuenta-publica-2324">
            <AuditoriasCP2324 tipoInforme={"Cuenta"} setTitle={updateTitle} />
        </Route>

        <Route exact path="/auditorias/cuentas-publicas-anteriores">
          <CuentasPublicasAnteriores tipoInforme={"Cuenta"} setTitle={updateTitle} />
        </Route>
        <Route exact path="/auditorias/avance-solventacion">
          <AvanceDeSolventacion setTitle={updateTitle} />
        </Route>
        <Route exact path="/auditorias/informes-de-resultados">
          <InformesDeResultados
            tipoInforme={"de Resultados"}
            setTitle={updateTitle}
          />
        </Route>
        <Route exact path="/auditorias/otros-informes">
          <OtrosInformes setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/planeacion-institucional">
          <PlaneacionInstitucional setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/acerca-de">
          <AcercaDe setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/codigo-de-etica">
          <CodigoDeEtica setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/compendio-legislativo">
          <CompendioLegislativo setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/directorio">
         <Directorio setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/estructura-organica">
          <EstructuraOrganica setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/cordinacion-archivo">
          <CordinacionArchivo setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/leyes-de-ingresos">
          <LeyesDeIngresos setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/denuncias">
          <Denuncias setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/denuncias-oci">
          <DenunciasOci setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/manuales-y-guias">
          <ManualesYGuias setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/organo-interno-de-control">
          <OrganoDeControlInterno setTitle={updateTitle} />
        </Route>
        <Route exact path="/transparencia/participacion-ciudadana">
          <ParticipacionCiudadana setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/capacitaciones">
          <Capacitaciones setTitle={updateTitle} />
        </Route>
        <Route exact path="/difusion/analisis-de-coyuntura">
          <EntradasBlog setTitle={updateTitle} />
        </Route>
        <Route exact path="/difusion/tableros-interactivos">
          <MapasInteractivos setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/analisis-de-coyuntura/share">
          <Entrada setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/preguntas-frecuentes">
          <PreguntasFrecuentes setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/marco-normativo">
          <MarcoNormativo/>
        </Route>
        <Route exact path="/difusion/notas">
          <Notas setTitle={updateTitle} />
        </Route>
        <Route exact path="/difusion/Foros-y-Eventos">
          <ForosYEventos setTitle={updateTitle} />
        </Route>
        <Route exact path="/difusion/nota">
          <Nota setTitle={updateTitle} />
        </Route>

        <Route exact path="/transparencia/convenios-municipales">
          <ConveniosMunicipales setTitle={updateTitle} />
        </Route>
        <Route exact path="/transparencia/informe-mensual-de-transparencia">
          <InformeMensualDeTransparencia setTitle={updateTitle} />
        </Route>
        <Route exact path="/transparencia/lgcc-y-ley-de-austeridad">
          <LGCCyLeyDeAuseteridad setTitle={updateTitle} />
        </Route>
        <Route exact path="/transparencia/obligaciones-de-transparencia">
          <OblicacionesDeTransparencia setTitle={updateTitle} />
        </Route>
        <Route exact path="/transparencia/procedimientos-de-acceso">
          <ProcedimientoDeAcceso setTitle={updateTitle} />
        </Route>
        <Route exact path="/transparencia/solicitud-de-informacion">
          <SolicitudDeInformacion setTitle={updateTitle} />
        </Route>

        <Route exact path="/transparencia/unidad-de-transparencia">
          <UnidadDeTransparencia setTitle={updateTitle} />
        </Route>
        <Route exact path="/transparencia/ObligacionesTransparencia/Fracciones/:articulo">
          <Fracciones />
        </Route>
        <Route exact path="/transparencia/obligaciones-de-transparencia/:articulo/:fraccion1"> <Fraccion />
        </Route>
        <Route exact path="/transparencia/aviso-privacidad">
          <AvisoPrivacidad setTitle={updateTitle} />
              </Route>
              <Route exact path="/transparencia/sevac">
                  <Sevac setTitle={updateTitle} />
              </Route>

        <Route exact path="/informes/informe-actividades">
          <InformeActividades setTitle={updateTitle} />
        </Route>
        <Route exact path="/inicio/informe-semestral">
          <InformeSemestral setTitle={updateTitle} />
        </Route>
        <Route exact path="/inicio/servicios-publicos">
          <ServiciosPublicos setTitle={updateTitle} />
        </Route>
        <Route exact path="/inicio/corrupcion">
          <Corrupcion setTitle={updateTitle} />
        </Route>

        <Route exact path="/inicio/corrupcion">
          <Corrupcion setTitle={updateTitle} />
        </Route>

        <Route exact path="/carnet">
          <Carnet setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/programa-anual">
          <ProgramaAnual setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/documentos-de-interes">
          <DocumentosDeInteres/>
        </Route>
        <Route exact path="/institucion/normatividad-interna">
          <NormatividadInterna setTitle={updateTitle} />
        </Route>
        <Route exact path="/transparencia/normatividad-transparencia">
          <NormatividadTransparencia setTitle={updateTitle} />
        </Route>
        <Route exact path="/informes/informes-cuenta-actual">
          <InformesCuentaActual setTitle={updateTitle} />
        </Route>
        <Route exact path="/informes/informes-cuenta-actual-detalle/:auditoriaId">
            <InformesCuentaActualDetalle setTitle={updateTitle} />
        </Route>
        <Route exact path="/institucion/acuerdos">
            <ListaAcuerdos />
        </Route>
        <Route exact path="/institucion/avisos">
            <ListaAvisos setTitle={updateTitle} />
        </Route>
        <Route exact path="/difusion/revista-valor-publico">
            <RevistaValorPublico setTitle={updateTitle} />
        </Route>
        <Route exact path="/informes/informes-tematicos">
            <InformesTematicos setTitle={updateTitle} />
        </Route>
             
        <Route exact path="/informes/politicas-publicas">
            <PoliticasPublicas setTitle={updateTitle} />
        </Route>
        <Route path="/404" component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </Layout>
  );
}
